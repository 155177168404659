
import { computed, defineComponent, onMounted, ref } from 'vue';
import store from '@/store';
import NotFound from '@/components/pages/404.vue';
import Spinner from '@/components/atoms/Spinner.vue';
import Card from '@/components/molecules/Card.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    NotFound,
    Spinner,
    Card,
    Pagination,
  },
  emits: ['loaded'],
  setup: (props, context) => {
    const state = computed(() => store.post.state);
    const posts = computed(() => store.post.state.index);
    const postsLoading = computed(() => store.post.state.loading);
    const $route = useRoute();
    const title = computed(() => store.option.state.get.option_value);
    const page = computed(() => {
      const matches = $route.path.match(/page\/(\d+)\/?$/);
      let page = 1;
      if (matches) {
        page = Number(matches[1] || 1);
      }

      return page;
    });
    const search = ref('');

    onMounted(async () => {
      const params = new URLSearchParams(window.location.search);
      const _search = params.get('search') || '';

      const option = {
        page: page.value,
        per_page: 12,
        search: _search,
      };

      await store.option.title();
      const pagination = page.value !== 1 ? `（${page.value}ページ）` : '';
      document.title = `${title.value}${pagination}` || '';
      const $meta =
        document.querySelector<HTMLMetaElement>('meta[name="description"]') || document.createElement('meta');
      $meta.setAttribute('name', 'description');
      $meta.setAttribute('content', document.title);
      const head = document.querySelector('head');
      head?.appendChild($meta);

      await store.post.index(option);
      search.value = _search;

      loaded();
    });

    const loaded = () => {
      context.emit('loaded');
    };

    return {
      state,
      posts,
      postsLoading,
      loaded,
      page,
      search,
    };
  },
});
